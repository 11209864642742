import { chakra, useMultiStyleConfig, Box, Grid } from '@chakra-ui/react'
import { CarHireLocationSearchDataTransferObject } from '@reward-platform/ancillaries-schemas/location'
import { Modal } from '@reward-platform/lift/components'
import React, { useMemo } from 'react'
import useIntl from '~/hooks/useIntl/useIntl'
import StaticMap from '../StaticMap'

export type LocationDetailsModalProps = {
  location: CarHireLocationSearchDataTransferObject
  isOpen: boolean
  onClose: () => void
}
function LocationDetailsModal({ location, isOpen, onClose }: LocationDetailsModalProps) {
  const intl = useIntl()
  const style = useMultiStyleConfig('LocationDetailsModalWrapper', {})

  const t = {
    location: intl.formatMessage({
      id: 'location-details-location',
      defaultMessage: 'Location',
    }),
    openingHours: intl.formatMessage({
      id: 'location-details-opening-hours',
      defaultMessage: 'Opening hours',
    }),
    twentyFourHourDropOff: intl.formatMessage({
      id: 'location-details-24-hour-drop-off',
      defaultMessage: '24 hour drop-off',
    }),
    twentyFourHourDropOffMsg: intl.formatMessage({
      id: 'location-details-24-hour-drop-off-msg',
      defaultMessage: 'Key drop off is available but the office will be closed out of hours.',
    }),
  }

  const locationOpeningTimesTable = useMemo(() => {
    if (location.openingTimes) {
      const rows = location.openingTimes.map((row): JSX.Element => {
        return (
          <chakra.tr key="day" role="row">
            <chakra.th role="cell">{row.day}</chakra.th>
            {row.hours.map((hours) => (
              <chakra.td key={hours} role="cell">
                {hours}
              </chakra.td>
            ))}
          </chakra.tr>
        )
      })
      return <chakra.table>{rows}</chakra.table>
    }
    return null
  }, [location.openingTimes])

  return (
    <Modal preventScroll onClose={onClose} isOpen={isOpen}>
      <Grid __css={style.locationDetails}>
        <Box gridArea="columnA" __css={style.locationContact}>
          <chakra.h1 mb={2}>{location.name}</chakra.h1>
          <chakra.p __css={style.phoneNumber} fontSize="md" fontWeight="medium">
            Call: {location.telephoneNumber}
          </chakra.p>
          <chakra.h2>{t.location}</chakra.h2>
          <chakra.address fontSize="md">
            {location?.address.map((loc: string) => (
              <span key={loc}>
                {loc} <br />
              </span>
            ))}
          </chakra.address>
          <Box __css={style.mapContainer}>
            {location?.location && (
              <StaticMap
                latitude={Number(location.location[0].latitude)}
                longitude={Number(location.location[0].longitude)}
                zoom={13}
                scale={5}
                format="png"
                language="en"
                displayMarker
              />
            )}
          </Box>
        </Box>
        <Box gridArea="columnB">
          <chakra.h2>{t.openingHours}</chakra.h2>
          {location?.openingTimes && locationOpeningTimesTable}
          {location.afterHoursIndicator && (
            <>
              <chakra.h3>{t.twentyFourHourDropOff}</chakra.h3>
              <chakra.p>{t.twentyFourHourDropOffMsg}</chakra.p>
            </>
          )}
        </Box>
      </Grid>
    </Modal>
  )
}

export default LocationDetailsModal
