import { z } from 'zod'
import {
  Location,
  CarHireLocationSearchDataTransferObjectSchema,
  CarHireLocationSearchDataTransferObject,
} from '@reward-platform/ancillaries-schemas/location'

export const CarHireSearchFormSchema = z.object({
  dob: z.date(),
  pickUpSearchString: z.string(),
  pickUpDate: z.date(),
  pickUpTime: z.string(),
  dropOffSearchString: z.string(),
  dropOffDate: z.date(),
  dropOffTime: z.string(),
  differentDropOff: z.boolean(),
  isUnder30: z.boolean(),
  pickUpLocation: CarHireLocationSearchDataTransferObjectSchema,
  dropOffLocation: CarHireLocationSearchDataTransferObjectSchema.optional(),
  membershipCode: z.string().optional(),
  isAvisPreferred: z.boolean(),
})

export type CarHireSearchForm = z.infer<typeof CarHireSearchFormSchema>
export type CarHireLocation = z.infer<typeof CarHireLocationSearchDataTransferObjectSchema>

export type CarHireLocationOption = {
  isLoading: boolean
  locations?: Location[] | CarHireLocation[]
  searchString: string
  setSearchString?: React.Dispatch<React.SetStateAction<string>>
  noResultsFound: boolean
  clearLocation: () => void
  onInputFocus: (e: { target: { select: () => void } }, openMenu: () => void) => void
  handleAutocompleteChange: (value?: string) => void
  handleSuggestionClick: (key: string) => void
  swapLocations: (newName: string, newLocation: CarHireLocationSearchDataTransferObject) => void
}
