import { useIntl } from 'react-intl'
import { MAX_DAYS_RENTAL } from './hooks/useCarHireSearchForm'
/**
 * Car Search specific translations
 */
export function useTranslations() {
  const intl = useIntl()

  const labels = {
    differentDropOff: intl.formatMessage({
      id: 'different-drop-off',
      defaultMessage: 'Different drop off location',
    }),
    driverUnder30: intl.formatMessage({
      id: 'driver-under-30',
      defaultMessage: 'Driver aged under 30',
    }),
    dob: intl.formatMessage({
      id: 'drivers-dob',
      defaultMessage: "Driver's date of birth",
    }),
    pickUpLocation: intl.formatMessage({
      id: 'pick-up-location',
      defaultMessage: 'Pick up location',
    }),
    dropOffLocation: intl.formatMessage({
      id: 'drop-off-location',
      defaultMessage: 'Drop off location',
    }),
    pickUpAndDropOffLocation: intl.formatMessage({
      id: 'pick-up-and-drop-off-location',
      defaultMessage: 'Pick up & drop off location',
    }),
    pickUpDate: intl.formatMessage({
      id: 'pick-up-date',
      defaultMessage: 'Pick up date',
    }),
    pickUpTime: intl.formatMessage({
      id: 'pick-up-time',
      defaultMessage: 'Pick up time',
    }),
    dropOffDate: intl.formatMessage({
      id: 'drop-off-date',
      defaultMessage: 'Drop off date',
    }),
    dropOffTime: intl.formatMessage({
      id: 'drop-off-time',
      defaultMessage: 'Drop off time',
    }),
    searchAgain: intl.formatMessage({
      id: 'search-again',
      defaultMessage: 'Search again',
    }),
    searchCarHire: intl.formatMessage({
      id: 'search-car-hire',
      defaultMessage: 'Search Car Hire',
    }),
    locationDetails: intl.formatMessage({
      id: 'location-details',
      defaultMessage: 'Location details',
    }),
    search: intl.formatMessage({
      id: 'search-label',
      defaultMessage: 'Searching...',
    }),
    noResults: intl.formatMessage({
      id: 'no-results',
      defaultMessage: 'No results found for ',
    }),
    avisPreferredMembership: intl.formatMessage({
      id: 'avis-preferred-membership',
      defaultMessage: 'Avis Preferred membership',
    }),
    avisPreferredMembershipLabel: intl.formatMessage({
      id: 'avis-preferred-membership-label',
      defaultMessage: 'Membership number',
    }),
    avisPreferredMembershipHelperInfo: intl.formatMessage({
      id: 'avis-preferred-membership-helper-info',
      defaultMessage:
        'Only tick the box if you are an existing member of the Avis loyalty programme to get beneficial rates on Avis offers.',
    }),
  }

  const errors = {
    missingPickUpLocation: intl.formatMessage({
      id: 'missing-pick-up',
      defaultMessage: 'Please enter a pick up location.',
    }),
    missingDropOffLocation: intl.formatMessage({
      id: 'missing-drop-off',
      defaultMessage: 'Please enter a drop off location.',
    }),
    dropOffInCountry: intl.formatMessage({
      id: 'drop-off-in-country',
      defaultMessage: 'Drop off location must be in the same country.',
    }),
    pickUpNotPast: intl.formatMessage({
      id: 'pick-up-not-past',
      defaultMessage: 'Pick up cannot be in the past.',
    }),
    timeNotAvailable: intl.formatMessage({
      id: 'time-not-available',
      defaultMessage: "Select a time within the location's opening hours.",
    }),
    minimumRentalPeriod: intl.formatMessage({
      id: 'minimum-rental-period',
      defaultMessage:
        'The minimum rental duration is 1 hour. Please adjust the drop off time and try again.',
    }),
    maximumRentalPeriod: intl.formatMessage(
      {
        id: 'maximum-rental-period',
        defaultMessage: `The maximum rental duration is {MAX_DAYS_RENTAL} days. Please adjust the drop off time and try again.`,
      },
      {
        MAX_DAYS_RENTAL,
      }
    ),
    minimumAvisPreferredMembershipLength: intl.formatMessage({
      id: 'minimum-avis-preferred-membership-length',
      defaultMessage:
        'Please ensure that it includes only letters and numbers, and is no longer than 6 characters.',
    }),
  }

  return { errors, labels }
}
