import { useMemo } from 'react'
import { CarHireSearchParams } from '@reward-platform/ancillaries-schemas/car-hire'
import { differenceInYears, format, add, sub } from 'date-fns'
import { CarHireSearchForm } from '../SearchCarHireForm/SearchCarHireForm.types'

export const useQueryStateToFormState = (
  queryState: CarHireSearchParams & { tab?: string }
): CarHireSearchForm => {
  const { pickUpLocation, dates, dropOffLocation, travelers, tab, membershipCode } = queryState

  return useMemo(
    () => ({
      pickUpSearchString: pickUpLocation ? pickUpLocation.fullName : '',
      pickUpDate: dates?.startDateTime
        ? new Date(dates.startDateTime)
        : add(new Date(), { days: 1 }),
      pickUpTime: dates?.startDateTime ? format(new Date(dates.startDateTime), 'HH:mm') : '10:00',
      dropOffSearchString: dropOffLocation ? dropOffLocation.fullName : '',
      dropOffDate: dates?.endDateTime
        ? new Date(dates.endDateTime)
        : add(new Date(), { days: tab === 'carhire' ? 3 : 2 }),
      dropOffTime: dates?.endDateTime ? format(new Date(dates.endDateTime), 'HH:mm') : '10:00',
      differentDropOff: !!(dropOffLocation && pickUpLocation.id !== dropOffLocation.id),
      isUnder30:
        travelers && travelers[0]?.dateOfBirth
          ? differenceInYears(new Date(), new Date(travelers[0]?.dateOfBirth)) < 30
          : false,
      dob: travelers?.[0]?.dateOfBirth
        ? new Date(travelers[0].dateOfBirth)
        : add(sub(new Date(), { years: 30 }), { days: 1 }),
      pickUpLocation: pickUpLocation || undefined,
      dropOffLocation: dropOffLocation || undefined,
      membershipCode: membershipCode ?? undefined,
      isAvisPreferred: membershipCode !== undefined,
    }),
    [pickUpLocation, dates, dropOffLocation, travelers, tab, membershipCode]
  )
}
