import { z } from 'zod'

export const DatesSchema = z.object({
  startDateTime: z.string(),
  endDateTime: z.string(),
})

export const ParamsSchema = z.object({
  dates: DatesSchema,
  page: z
    .string()
    .default('1')
    .transform((val) => Number(val)),
})

export type Dates = z.infer<typeof DatesSchema>
