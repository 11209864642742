/* eslint-disable @typescript-eslint/no-shadow */
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { ForwardedRef, useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { differenceInDays, format, isSameDay } from 'date-fns'
import { stringify } from 'qs'
import { ONE_DAY, TWO_DAYS } from '~/utils/datetime'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { DatePickerProps } from '@reward-platform/lift/components/DatePicker/DatePicker.types'
import { CarHireSearchForm } from '../SearchCarHireForm.types'
import { useCarHireSearchFormValidation } from './useCarHireSearchFormValidation'
import {
  setHoursandMinutesOnDate,
  getHoursMinutesFrom24hrString,
  addHourTo24hrString,
} from '../helpers'

export const SEARCH_TYPE = 'CAR_HIRE'

export const DATETIME_FORMAT = "y-MM-dd'T'HH:mm"

export const MAX_DAYS_RENTAL = 27

export function useCarHireSearchForm(
  defaultValues: CarHireSearchForm,
  toggleRef?: ForwardedRef<HTMLButtonElement>
) {
  const { push } = useRouter()
  const CarHireSearchFormValidationSchema = useCarHireSearchFormValidation()

  const form = useForm<CarHireSearchForm>({
    defaultValues,
    resolver: zodResolver(CarHireSearchFormValidationSchema),
  })

  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form

  // watch checkboxes for associated re-render functionality
  const [isUnder30, differentDropOff, isAvisPreferred] = watch([
    'isUnder30',
    'differentDropOff',
    'isAvisPreferred',
  ])

  // getValues for remainder of form values
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pickUpLocation, dropOffLocation, pickUpDate, pickUpTime, dropOffDate, membershipCode] =
    getValues([
      'pickUpLocation',
      'dropOffLocation',
      'pickUpDate',
      'pickUpTime',
      'dropOffDate',
      'membershipCode',
    ])

  const maxDropOffDate = useMemo(
    () => new Date(pickUpDate.getTime() + MAX_DAYS_RENTAL * ONE_DAY),
    [pickUpDate]
  )

  const setDateState =
    (setStateFunc: (date: Date) => void): DatePickerProps['onChange'] =>
    (date) => {
      if (!date || Array.isArray(date)) {
        return
      }
      form.clearErrors()
      setStateFunc(date)
    }

  const updatePickUpDate = (date: Date) => {
    if (
      dropOffDate.valueOf() < date.valueOf() + TWO_DAYS ||
      dropOffDate.valueOf() > date.valueOf() + TWO_DAYS * MAX_DAYS_RENTAL
    ) {
      setValue('dropOffDate', new Date(date.valueOf() + TWO_DAYS))
    }
    setValue('pickUpDate', date)
  }

  const updateDropOffDate = (date: Date) => {
    if (isSameDay(date, pickUpDate)) {
      const pickUpTimePlusOneHour = addHourTo24hrString(pickUpTime)
      setValue('dropOffTime', pickUpTimePlusOneHour)
    }
    if (differenceInDays(date, pickUpDate) === MAX_DAYS_RENTAL) {
      setValue('dropOffTime', pickUpTime)
    }
    setValue('dropOffDate', date)
  }

  useEffect(() => {
    if (differenceInDays(dropOffDate, pickUpDate) === MAX_DAYS_RENTAL) {
      setValue('dropOffTime', pickUpTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpLocation])

  const submitForm: SubmitHandler<FieldValues> = (values) => {
    const {
      pickUpLocation,
      dropOffLocation,
      pickUpDate,
      pickUpTime,
      dropOffDate,
      dropOffTime,
      dob,
      membershipCode,
    } = values

    const { hours: pickUpHour, minutes: pickUpMinute } = getHoursMinutesFrom24hrString(pickUpTime)
    const { hours: dropOffHour, minutes: dropOffMinute } =
      getHoursMinutesFrom24hrString(dropOffTime)

    const pickUpDateWithTime = setHoursandMinutesOnDate(pickUpDate, pickUpHour, pickUpMinute)
    const dropOffDateWithTime = setHoursandMinutesOnDate(dropOffDate, dropOffHour, dropOffMinute)

    const searchData = {
      searchType: SEARCH_TYPE,
      dates: {
        startDateTime: format(pickUpDateWithTime, DATETIME_FORMAT),
        endDateTime: format(dropOffDateWithTime, DATETIME_FORMAT),
      },
      pickUpLocation: { ...pickUpLocation },
      dropOffLocation: differentDropOff ? { ...dropOffLocation } : { ...pickUpLocation },
      travelers: [
        {
          ageGroup: 'ADULT',
          dateOfBirth: isUnder30 ? format(dob, 'y-MM-dd') : '',
        },
      ],
      shoppingFilter: { carShoppingFilter: { sortBy: 'PRICE', orderBy: 'ASC' } },
      page: 1,
      vendorBrandId: pickUpLocation?.vendorBrandId,
      membershipCode,
    }

    if (toggleRef && typeof toggleRef !== 'function') {
      toggleRef?.current?.click()
    }

    push({
      pathname: '/cars/results',
      query: stringify(searchData),
    })
  }

  return {
    form,
    submitForm,
    isUnder30,
    setDateState,
    pickUpLocation,
    dropOffLocation,
    errors,
    updatePickUpDate,
    updateDropOffDate,
    pickUpDate,
    differentDropOff,
    maxDropOffDate,
    dropOffDate,
    isAvisPreferred,
    membershipCode,
  }
}
