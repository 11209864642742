import { useMemo } from 'react'
import { differenceInMinutes } from 'date-fns'
import useIntl from '~/hooks/useIntl/useIntl'
import { setTimeToDate } from '~/utils/datetime'
import { Availability } from '@reward-platform/lift/components/TimePicker/interface'
import { checkDisabledTime, getDateTime } from '@reward-platform/lift/utils/datetime'
import { useTranslations } from '../SearchCarHireForm.translations'
import { CarHireSearchFormSchema } from '../SearchCarHireForm.types'
import { validateTimeLaterThanMinimumTime } from '../helpers/validateTimeLaterThanMinimumTime'
import { MAX_DAYS_RENTAL } from './useCarHireSearchForm'

export function useCarHireSearchFormValidation() {
  const { locale } = useIntl()
  const { errors: errorText } = useTranslations()

  const CarHireSearchFormValidationSchema = CarHireSearchFormSchema.partial()
    .refine(({ pickUpLocation }) => pickUpLocation?.countryCode, {
      path: ['pickUpSearchString'],
      message: errorText.missingPickUpLocation,
    })
    .refine(
      ({ differentDropOff, dropOffLocation }) => !differentDropOff || dropOffLocation?.countryCode,
      {
        path: ['dropOffSearchString'],
        message: errorText.missingDropOffLocation,
      }
    )
    .refine(
      ({ pickUpLocation, pickUpTime, pickUpDate }) =>
        !pickUpLocation?.countryCode
          ? true
          : !checkDisabledTime(
              locale,
              pickUpTime ?? '',
              pickUpDate,
              pickUpLocation?.openingTimes as Availability[]
            ),
      {
        path: ['pickUpTime'],
        message: errorText.timeNotAvailable,
      }
    )
    .refine(
      ({ pickUpTime, pickUpDate, dropOffTime, dropOffDate }) => {
        const pickUpDateTime = setTimeToDate(pickUpDate!.toISOString(), pickUpTime)
        const dropOffDateTime = setTimeToDate(dropOffDate!.toISOString(), dropOffTime)
        return differenceInMinutes(dropOffDateTime, pickUpDateTime) / 60 / 24 <= MAX_DAYS_RENTAL
      },
      {
        path: ['dropOffTime'],
        message: errorText.maximumRentalPeriod,
      }
    )
    .refine(
      ({ pickUpLocation, pickUpTime, pickUpDate }) =>
        !pickUpLocation?.countryCode || !pickUpTime || !pickUpDate
          ? true
          : validateTimeLaterThanMinimumTime(pickUpTime, pickUpDate),
      {
        path: ['pickUpTime'],
        message: errorText.pickUpNotPast,
      }
    )
    .refine(
      ({ pickUpLocation, dropOffTime, dropOffDate, pickUpTime, pickUpDate }) =>
        !pickUpLocation?.countryCode
          ? true
          : dropOffDate &&
            validateTimeLaterThanMinimumTime(
              dropOffTime ?? '',
              dropOffDate,
              pickUpTime,
              pickUpDate
            ),
      {
        path: ['dropOffTime'],
        message: errorText.minimumRentalPeriod,
      }
    )
    .refine(
      ({
        pickUpLocation,
        dropOffTime,
        dropOffDate,
        differentDropOff,
        dropOffLocation,
        pickUpTime,
        pickUpDate,
      }) => {
        if (!pickUpLocation?.countryCode) {
          return true
        }
        /**
         * If the drop off location is the same as the pick up location, we need to check the opening times of the pick up location. If the dropOffDate is the same as the pickUpDate, we need to check that the dropOffTime is after the pickUpTime.
         */
        const openingTimes = differentDropOff
          ? (dropOffLocation?.openingTimes as Availability[])
          : (pickUpLocation?.openingTimes as Availability[])
        const minDateTime =
          pickUpTime && pickUpDate ? getDateTime(pickUpTime, pickUpDate) : undefined
        return !checkDisabledTime(locale, dropOffTime ?? '', dropOffDate, openingTimes, minDateTime)
      },
      {
        path: ['dropOffTime'],
        message: errorText.timeNotAvailable,
      }
    )
    .refine(
      ({ pickUpLocation, differentDropOff, dropOffLocation }) =>
        !differentDropOff || pickUpLocation?.countryCode === dropOffLocation?.countryCode,
      {
        path: ['dropOffSearchString'],
        message: errorText.dropOffInCountry,
      }
    )
    .refine(
      ({ membershipCode, isAvisPreferred }) => {
        const regexp = /^[A-Za-z0-9]{1,6}$/

        if (membershipCode && isAvisPreferred) {
          return regexp.test(membershipCode.trim().toUpperCase())
        }
        return true
      },
      {
        path: ['membershipCode'],
        message: errorText.minimumAvisPreferredMembershipLength,
      }
    )

  return useMemo(() => CarHireSearchFormValidationSchema, [CarHireSearchFormValidationSchema])
}
