import { getDateTime } from '@reward-platform/lift/utils/datetime'
import { isBefore, addMinutes } from 'date-fns'

export const validateTimeLaterThanMinimumTime = (
  endTime: string,
  endDate: Date,
  startTime?: string,
  startDate?: Date
): boolean => {
  const now = new Date()

  let startDateTime = now
  if (startTime && startDate) {
    startDateTime = getDateTime(startTime, startDate)
  }

  const endDateTime = getDateTime(endTime, endDate)

  return isBefore(addMinutes(startDateTime, 59), endDateTime)
}
