export const enum SearchCarHireLocationType {
  PickUpLocation = 'pickUpLocation',
  DropOffLocation = 'dropOffLocation',
}

export type SearchCarHireLocationDetailsProps = {
  locationType: SearchCarHireLocationType
  label: string
  toggleVisibility: (locationType: SearchCarHireLocationType) => void
}
