/* eslint-disable sonarjs/no-duplicate-string */
// React and Next
import React, { useState, useMemo, useCallback } from 'react'

// Third Parties
import { Controller } from 'react-hook-form'
import { useRouter } from 'next/router'
import { differenceInDays } from 'date-fns'
import useIntl from '~/hooks/useIntl/useIntl'

// Components
import {
  AutoCompleteLocationOption,
  AutoCompleteWithMobileModal,
  DatePicker,
  TimePicker,
  Checkbox,
  ChakraInput,
} from '@reward-platform/lift/components'
// Services
// Utils
import { createAutoCompleteLocationSource } from '~/utils/createAutoCompleteSourceFromLocation'
// Types
import { CarHireSearchParams } from '@reward-platform/ancillaries-schemas/car-hire'
import { useQueryParams } from '~/hooks/useQueryParams/useQueryParams'
import { useUser } from '~/context/user'
import { LocationOptionProps } from '@reward-platform/lift/components/AutoComplete/components/AutoCompleteLocationOption'
import { getDateTime } from '@reward-platform/lift/utils/datetime'
import {
  Box,
  Grid,
  GridItem,
  Button,
  useMultiStyleConfig,
  useMediaQuery,
  Icon,
  chakra,
  SystemStyleObject,
} from '@chakra-ui/react'
import {
  gtmSearchSubmit,
  refineSearchSessionStorage,
} from '~/utils/googleTagManager/googleTagManagerHelperFunctions'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { Tooltip } from '@reward-platform/lift/components/Tooltip/Tooltip'
import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import LocationDetailsModal from '../LocationDetailsModal/LocationDetailsModal'
// Hooks
import { useQueryStateToFormState } from '../CarResults/useQueryToFormState'
import { useTranslations } from './SearchCarHireForm.translations'
import {
  useCarHireSearchFormDates,
  useCarHireSearchForm,
  useCarHireSearchFormLocation,
} from './hooks'
import { CarHireLocationOption } from './SearchCarHireForm.types'
import {
  SearchCarHireLocationDetails,
  SearchCarHireLocationType,
} from './SearchCarHireLocationDetails'
import { SearchFieldSwapButton } from '../SearchFieldSwapButton'
import { MAX_DAYS_RENTAL } from './hooks/useCarHireSearchForm'

const AutoCompleteOption = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    borderRadius="8px"
    boxShadow={{ base: 'none', md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)' }}
    padding="24px"
    color="rgba(99, 102, 106, 1)"
    cursor="default"
  >
    {children}
  </Box>
)

const locationOptions = (
  autocompleteData: CarHireLocationOption,
  autocompleteOptions: LocationOptionProps[],
  labels: Record<string, string>
) => {
  if (autocompleteData.isLoading) {
    return <AutoCompleteOption>{labels.search}</AutoCompleteOption>
  }
  if (autocompleteData.noResultsFound) {
    return (
      <AutoCompleteOption>
        {labels.noResults} <b>"{autocompleteData.searchString}"</b>
      </AutoCompleteOption>
    )
  }
  return autocompleteOptions.map((item, index) => {
    return <AutoCompleteLocationOption key={item.key} item={item} index={index} />
  })
}

const refCallbackFn = (isLargerThanMobile: boolean, autocompleteOptions: CarHireLocationOption) => {
  if (!isLargerThanMobile) {
    return undefined
  }
  return (el: HTMLInputElement | null) => {
    if (el) {
      el.value = autocompleteOptions.searchString
    }
  }
}

const getPickUpLocationDetailsMessage = (
  fullName: string,
  locationDetails: string,
  toggleLocationsDetailModalVisibility: (locationType: SearchCarHireLocationType) => void
) =>
  fullName ? (
    <SearchCarHireLocationDetails
      locationType={SearchCarHireLocationType.PickUpLocation}
      label={locationDetails}
      toggleVisibility={toggleLocationsDetailModalVisibility}
    />
  ) : undefined

const getDropOffLocationDetailsMessage = (
  differentDropOff: boolean,
  fullName: string | undefined,
  locationDetails: string,
  toggleLocationsDetailModalVisibility: (locationType: SearchCarHireLocationType) => void
) =>
  differentDropOff && fullName ? (
    <SearchCarHireLocationDetails
      locationType={SearchCarHireLocationType.DropOffLocation}
      label={locationDetails}
      toggleVisibility={toggleLocationsDetailModalVisibility}
    />
  ) : undefined

const applyDateOfBirthStyle = (
  isLargerThanMobile: boolean,
  isSmallerThanLarge: boolean,
  isAvisPreferred: boolean,
  isUnder30: boolean,
  styles: Record<string, SystemStyleObject>
) =>
  isLargerThanMobile && isSmallerThanLarge && !isAvisPreferred && isUnder30
    ? styles.driverAndPreferredInput
    : styles.birthDatePicker

const applyAvisPreferredStyle = (
  isLarge: boolean,
  isUnder30: boolean,
  styles: Record<string, SystemStyleObject>
) => (isLarge && !isUnder30 ? styles.isAvisPreferredAlign : styles.isAvisPreferred)

const getMembershipCodeStyle = (
  isLarge: boolean,
  isLargerThanMobile: boolean,
  isSmallerThanLarge: boolean,
  isAvisPreferred: boolean,
  isUnder30: boolean,
  styles: Record<string, SystemStyleObject>
) => {
  if (isLarge && !isUnder30) {
    return styles.membershipCodeLarge
  }

  return isLargerThanMobile && isSmallerThanLarge && isAvisPreferred && !isUnder30
    ? styles.driverAndPreferredInput
    : styles.membershipCode
}

const SearchCarHireForm = React.forwardRef<HTMLButtonElement, { buttonText: string }>(
  (props: { buttonText: string }, toggleRef): JSX.Element => {
    const { buttonText } = props
    const { userLocale } = useUser()
    const { labels } = useTranslations()
    const { locale } = useIntl()
    const { pathname } = useRouter()

    const [isLargerThanMobile] = useMediaQuery(`(min-width: ${deviceSizes.medium})`)
    const [isSmallerThanLarge] = useMediaQuery(`(max-width: 1023px)`)
    const [isLarge] = useMediaQuery(`(min-width: ${deviceSizes.large})`)

    const avisPreferredMembership = useFeatureFlag('car-hire-avis-preferred-membership')

    const { query } = useQueryParams<CarHireSearchParams>()
    const defaultFormValues = useQueryStateToFormState(query)

    const { minYear, maxYear, minPickUpDate, maxPickUpDate } = useCarHireSearchFormDates()

    const {
      form,
      submitForm,
      isUnder30,
      setDateState,
      pickUpLocation,
      dropOffLocation,
      errors,
      updatePickUpDate,
      updateDropOffDate,
      pickUpDate,
      differentDropOff,
      maxDropOffDate,
      dropOffDate,
      isAvisPreferred,
    } = useCarHireSearchForm(defaultFormValues, toggleRef)

    const pickUpAutocomplete = useCarHireSearchFormLocation(
      form,
      defaultFormValues.pickUpSearchString,
      'pickUpLocation',
      'pickUpSearchString'
    )
    const pickUpAutocompleteItems = useMemo(
      () => createAutoCompleteLocationSource(pickUpAutocomplete.locations, true),
      [pickUpAutocomplete.locations]
    )

    const dropOffAutocomplete = useCarHireSearchFormLocation(
      form,
      defaultFormValues.dropOffSearchString,
      'dropOffLocation',
      'dropOffSearchString'
    )
    const dropOffAutocompleteItems = useMemo(
      () => createAutoCompleteLocationSource(dropOffAutocomplete.locations, true),
      [dropOffAutocomplete.locations]
    )
    const dropOffInputRefCallbackFn = refCallbackFn(isLargerThanMobile, dropOffAutocomplete)
    const pickUpInputRefCallbackFn = refCallbackFn(isLargerThanMobile, pickUpAutocomplete)

    const { control, handleSubmit } = form

    const styles = useMultiStyleConfig('SearchCarHireForm')

    const [isLocationDetailsModalVisible, setIsLocationDetailsModalVisible] = useState({
      pickUpLocation: false,
      dropOffLocation: false,
    })

    const toggleLocationsDetailModalVisibility = useCallback(
      (locationType: SearchCarHireLocationType) => {
        setIsLocationDetailsModalVisible({
          ...isLocationDetailsModalVisible,
          [locationType]: !isLocationDetailsModalVisible[locationType],
        })
      },
      [isLocationDetailsModalVisible]
    )

    const pickUpLocationDetailsMessage = getPickUpLocationDetailsMessage(
      pickUpLocation?.fullName,
      labels.locationDetails,
      toggleLocationsDetailModalVisibility
    )

    const dropOffLocationDetailsMessage = getDropOffLocationDetailsMessage(
      differentDropOff,
      dropOffLocation?.fullName,
      labels.locationDetails,
      toggleLocationsDetailModalVisibility
    )

    const pushGtaCarSearchAgain = () => {
      refineSearchSessionStorage(pathname)
      return gtmSearchSubmit('car hire')
    }

    const swapLocations = () => {
      if (!dropOffLocation) {
        return
      }
      const [pickUpSearchString, dropOffSearchString] = form.getValues([
        'pickUpSearchString',
        'dropOffSearchString',
      ])

      dropOffAutocomplete?.swapLocations(pickUpSearchString, pickUpLocation)
      pickUpAutocomplete?.swapLocations(dropOffSearchString, dropOffLocation)
    }
    const isSwapDisabled = useMemo(
      () => !pickUpLocation || !dropOffLocation || !differentDropOff,
      [differentDropOff, dropOffLocation, pickUpLocation]
    )

    const isLastAvailableRentalDay = differenceInDays(dropOffDate, pickUpDate) === MAX_DAYS_RENTAL

    return (
      <form autoComplete="off" onSubmit={handleSubmit(submitForm)}>
        <Grid sx={styles.formWrapper}>
          {isLocationDetailsModalVisible.pickUpLocation && pickUpLocation && (
            <LocationDetailsModal
              location={pickUpLocation}
              onClose={() =>
                toggleLocationsDetailModalVisibility(SearchCarHireLocationType.PickUpLocation)
              }
              isOpen={isLocationDetailsModalVisible.pickUpLocation}
            />
          )}
          {isLocationDetailsModalVisible.dropOffLocation && dropOffLocation && (
            <LocationDetailsModal
              location={dropOffLocation}
              onClose={() =>
                toggleLocationsDetailModalVisibility(SearchCarHireLocationType.DropOffLocation)
              }
              isOpen={isLocationDetailsModalVisible.dropOffLocation}
            />
          )}
          <GridItem sx={styles.formCheckboxes}>
            <Controller
              control={control}
              name="differentDropOff"
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Checkbox
                    id="differentDropOff"
                    name={name}
                    value="true"
                    isChecked={value}
                    onChange={() => {
                      dropOffAutocomplete.clearLocation()
                      return onChange(!value)
                    }}
                  >
                    {labels.differentDropOff}
                  </Checkbox>
                )
              }}
            />
          </GridItem>
          <GridItem sx={styles.locationFields}>
            <Controller
              control={control}
              name="pickUpSearchString"
              render={({ field: { onChange } }) => (
                <GridItem sx={styles.pickUpSearchString}>
                  <AutoCompleteWithMobileModal
                    name="pickUpLocation"
                    label={
                      differentDropOff ? labels.pickUpLocation : labels.pickUpAndDropOffLocation
                    }
                    value={pickUpAutocomplete.searchString}
                    items={pickUpAutocompleteItems}
                    onInputFocus={pickUpAutocomplete.onInputFocus}
                    onInputValueChange={(val) => {
                      pickUpAutocomplete.handleAutocompleteChange(val.inputValue)
                    }}
                    onSelectedItemChange={(v) => {
                      pickUpAutocomplete.handleSuggestionClick(v.selectedItem?.value || '')
                      dropOffAutocomplete.clearLocation()
                    }}
                    error={errors.pickUpSearchString?.message || ''}
                    helperMessage={pickUpLocationDetailsMessage}
                    ref={pickUpInputRefCallbackFn}
                  >
                    {locationOptions(pickUpAutocomplete, pickUpAutocompleteItems, labels)}
                  </AutoCompleteWithMobileModal>
                </GridItem>
              )}
            />
            {differentDropOff && (
              <>
                <Box sx={styles.swapContainer}>
                  <SearchFieldSwapButton
                    onClick={swapLocations}
                    disabled={isSwapDisabled}
                    label="Swap car hire pick up and drop off locations"
                  />
                </Box>
                <Controller
                  control={control}
                  name="dropOffSearchString"
                  render={({ field: { onChange } }) => (
                    <GridItem sx={styles.dropOffSearchString}>
                      <AutoCompleteWithMobileModal
                        name="dropOffLocation"
                        label={labels.dropOffLocation}
                        value={dropOffAutocomplete.searchString}
                        items={dropOffAutocompleteItems}
                        onInputFocus={dropOffAutocomplete.onInputFocus}
                        onInputValueChange={(val) => {
                          dropOffAutocomplete.handleAutocompleteChange(val.inputValue)
                        }}
                        onSelectedItemChange={(v) => {
                          dropOffAutocomplete.handleSuggestionClick(v.selectedItem?.value || '')
                        }}
                        error={errors.dropOffSearchString?.message || ''}
                        disabled={!differentDropOff}
                        helperMessage={dropOffLocationDetailsMessage}
                        ref={dropOffInputRefCallbackFn}
                      >
                        {locationOptions(dropOffAutocomplete, dropOffAutocompleteItems, labels)}
                      </AutoCompleteWithMobileModal>
                    </GridItem>
                  )}
                />
              </>
            )}
          </GridItem>
          <Controller
            control={control}
            name="pickUpDate"
            render={({ field: { value } }) => (
              <GridItem sx={styles.pickUpDate}>
                <DatePicker
                  name="pickUpDate"
                  label={labels.pickUpDate}
                  monthsShown={1}
                  minDate={minPickUpDate}
                  maxDate={maxPickUpDate}
                  selected={value}
                  onChange={setDateState(updatePickUpDate)}
                  error={errors.pickUpDate?.message}
                  locale={userLocale}
                />
              </GridItem>
            )}
          />
          <Controller
            control={control}
            name="pickUpTime"
            render={({ field: { onChange, value } }) => (
              <GridItem sx={styles.pickUpTime}>
                <TimePicker
                  label={labels.pickUpTime}
                  value={value}
                  setTime={(newValue) => {
                    onChange(newValue)
                  }}
                  errors={errors.pickUpTime?.message ? [errors.pickUpTime.message] : undefined}
                  availability={pickUpLocation?.openingTimes}
                  chosenDate={pickUpDate}
                  locale={locale}
                />
              </GridItem>
            )}
          />
          <Controller
            control={control}
            name="dropOffDate"
            render={({ field: { onChange, value } }) => (
              <GridItem sx={styles.dropOffDate}>
                <DatePicker
                  name="dropOffDate"
                  monthsShown={1}
                  label={labels.dropOffDate}
                  minDate={pickUpDate}
                  maxDate={maxDropOffDate}
                  selected={value}
                  onChange={setDateState(updateDropOffDate)}
                  error={errors.dropOffDate?.message}
                  locale={userLocale}
                />
              </GridItem>
            )}
          />
          <Controller
            control={control}
            name="dropOffTime"
            render={({ field: { onChange, value } }) => (
              <GridItem sx={styles.dropOffTime}>
                <TimePicker
                  label={labels.dropOffTime}
                  value={value}
                  setTime={(newValue) => {
                    onChange(newValue)
                  }}
                  errors={errors.dropOffTime?.message ? [errors.dropOffTime.message] : undefined}
                  availability={dropOffLocation?.openingTimes || pickUpLocation?.openingTimes}
                  minDateTime={getDateTime(form.getValues('pickUpTime'), pickUpDate)}
                  chosenDate={dropOffDate}
                  locale={locale}
                  isLastAvailableRentalDay={isLastAvailableRentalDay}
                />
              </GridItem>
            )}
          />
          <GridItem sx={styles.under30Checkbox}>
            <Controller
              control={control}
              name="isUnder30"
              render={({ field: { onChange, value, name } }) => (
                <Checkbox
                  id="isUnder30"
                  name={name}
                  value="true"
                  isChecked={value}
                  onChange={onChange}
                >
                  {labels.driverUnder30}
                </Checkbox>
              )}
            />
          </GridItem>

          {isUnder30 && (
            <Controller
              control={control}
              name="dob"
              render={({ field: { onChange, value } }) => (
                <GridItem
                  sx={applyDateOfBirthStyle(
                    isLargerThanMobile,
                    isSmallerThanLarge,
                    isAvisPreferred,
                    isUnder30,
                    styles
                  )}
                >
                  <DatePicker
                    name="dob"
                    label={labels.dob}
                    monthsShown={1}
                    showYearDropdown
                    minDate={minYear}
                    maxDate={maxYear}
                    selected={value}
                    onChange={setDateState(onChange)}
                    locale={userLocale}
                  />
                </GridItem>
              )}
            />
          )}

          {avisPreferredMembership && (
            <GridItem sx={applyAvisPreferredStyle(isLarge, isUnder30, styles)}>
              <Controller
                control={control}
                name="isAvisPreferred"
                render={({ field: { onChange, value, name } }) => (
                  <Checkbox
                    id="isAvisPreferred"
                    name={name}
                    value="true"
                    isChecked={value}
                    onChange={onChange}
                  >
                    {labels.avisPreferredMembership}
                    <Tooltip
                      content={<chakra.p>{labels.avisPreferredMembershipHelperInfo}</chakra.p>}
                      icon={
                        <Icon
                          as={convertIconSetToChakraSVG(IconName.Enum.info)}
                          boxSize={IconSizes.sm}
                          sx={styles.avisPreferredInfo}
                        />
                      }
                      closeButtonText="OK"
                    />
                  </Checkbox>
                )}
              />
            </GridItem>
          )}

          {isAvisPreferred && avisPreferredMembership && (
            <Controller
              control={control}
              name="membershipCode"
              render={({ field: { onChange, value } }) => (
                <GridItem
                  sx={getMembershipCodeStyle(
                    isLarge,
                    isLargerThanMobile,
                    isSmallerThanLarge,
                    isAvisPreferred,
                    isUnder30,
                    styles
                  )}
                >
                  <ChakraInput
                    name="membershipCode"
                    label={labels.avisPreferredMembershipLabel}
                    onInput={(newValue) => {
                      onChange(newValue)
                    }}
                    value={value}
                    error={errors.membershipCode?.message ?? undefined}
                    errorIcon="info"
                    maxLength={6}
                  />
                </GridItem>
              )}
            />
          )}

          <GridItem sx={styles.submitButtonContainer}>
            <Button
              sx={styles.submitButton}
              variant="primary"
              type="submit"
              onClick={pushGtaCarSearchAgain}
            >
              {buttonText}
            </Button>
          </GridItem>
        </Grid>
      </form>
    )
  }
)

export default SearchCarHireForm
