import { useMemo } from 'react'

export function useCarHireSearchFormDates() {
  // Date-related calculations using useMemo
  const minAge = useMemo(() => {
    const age = new Date()
    age.setFullYear(age.getFullYear() - 18)
    return age
  }, [])

  const minYear = useMemo(() => {
    const year = new Date()
    year.setFullYear(year.getFullYear() - 30)
    return year
  }, [])

  const maxYear = useMemo(() => {
    const year = new Date()
    year.setFullYear(year.getFullYear() - 18) // 2003
    return year
  }, [])

  const maxPickUpDate = useMemo(() => {
    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() + 1)
    return maxDate
  }, [])

  const minPickUpDate = useMemo(() => {
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)
    return minDate
  }, [])

  return {
    minAge,
    minYear,
    maxYear,
    maxPickUpDate,
    minPickUpDate,
  }
}
