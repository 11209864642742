import React from 'react'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { Icon, Text, Button, useMultiStyleConfig } from '@chakra-ui/react'
import { SearchCarHireLocationDetailsProps } from './SearchCarHireLocationDetails.types'

export function SearchCarHireLocationDetails({
  locationType,
  label,
  toggleVisibility,
}: SearchCarHireLocationDetailsProps) {
  const styles = useMultiStyleConfig('SearchCarHireForm')

  return (
    <Text sx={styles.helperMessageText}>
      <Icon
        as={convertIconSetToChakraSVG(IconName.Enum.info)}
        boxSize={IconSizes.md}
        color="brand.icon"
      />
      <Button
        variant="link"
        sx={styles.helperMessageButton}
        onClick={() => toggleVisibility(locationType)}
        type="button"
      >
        {/* {labels.locationDetails} */}
        {label}
      </Button>
    </Text>
  )
}
